<template>
  <div class="tax-config">
    <b-modal
      ref="tax-config-modal"
      title="Cấu hình thuế TNCN"
      hide-footer
      size="xl"
    >
      <b-row>
        <b-col cols="3">
          <b-form-group class="required-control">
            <label>Tên cấu hình:</label>
            <b-form-input
              size="sm"
              v-model="taxConfigModel.name"
              placeholder="Nhập tên cấu hình"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group class="required-control">
            <label>Loại HĐLĐ:</label>
            <b-form-select
              size="sm"
              class="select-style"
              v-model="taxConfigModel.contractType"
              :options="[
                {
                  id: 1,
                  name: 'Chính thức',
                },
                {
                  id: 2,
                  name: 'Thử việc',
                },
              ]"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group class="required-control">
            <label>Trạng thái hoạt động:</label>
            <b-form-select
              size="sm"
              class="select-style"
              v-model="taxConfigModel.isActive"
              :options="[
                {
                  id: 1,
                  name: 'Hoạt động',
                },
                {
                  id: 0,
                  name: 'Ngừng hoạt động',
                },
              ]"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        class="mb-4 font-weight-bolder"
        @click="onClickToAdd"
        size="sm"
        variant="primary"
      >
        <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
      </b-button>
      <b-table bordered hover :fields="fields" :items="taxConfigModel.details">
        <template v-slot:cell(incomeFrom)="row">
          <BInputNumber
            size="sm"
            v-model="row.item.incomeFrom"
            placeholder="Nhập mức thu nhập từ"
            class="text-right"
          />
        </template>
        <template v-slot:cell(incomeTo)="row">
          <BInputNumber
            size="sm"
            v-model="row.item.incomeTo"
            placeholder="Nhập mức thu nhập đến"
            class="text-right"
          />
        </template>
        <template v-slot:cell(taxAmount)="row">
          <BInputNumber
            size="sm"
            v-model="row.item.taxAmount"
            placeholder="Nhập thuế suất"
            class="text-right"
          />
        </template>
        <template v-slot:cell(actions)="row">
          <v-icon
            small
            class="text-danger text-center"
            @click="deleteItemInArray(row.item.id)"
            v-b-tooltip
            title="Xóa"
            >mdi-delete</v-icon
          >
        </template>
      </b-table>
      <b-button
        style="fontweight: 600; width: 100px"
        variant="primary"
        size="sm"
        @click="onSave()"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModal()"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>
<style lang="scss">
.tax-config {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
}
</style>
<script>
import { maxBy } from 'lodash';
import { v4 } from 'uuid';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      taxConfigModel: {
        id: null,
        name: '',
        contractType: 1,
        isActive: 1,
        details: [],
      },
      fields: [
        {
          key: 'level',
          label: 'Bậc',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'incomeFrom',
          label: 'Mức thu nhập từ',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-right',
        },
        {
          key: 'incomeTo',
          label: 'Mức thu nhập đến',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-right',
        },
        {
          key: 'taxAmount',
          label: 'Thuế suất',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
    };
  },
  methods: {
    showModal(configId) {
      this.taxConfigModel = {
        id: null,
        name: '',
        contractType: 1,
        isActive: 1,
        details: [],
      };
      if (configId) {
        this.getInfoById(configId);
      }
      this.$refs['tax-config-modal'].show();
    },
    hideModal() {
      this.$refs['tax-config-modal'].hide();
    },
    onSave() {
      const payload = {
        name: this.taxConfigModel.name.trim(),
        contractType: this.taxConfigModel.contractType,
        isActive: this.taxConfigModel.isActive,
        details: this.taxConfigModel.details,
      };
      if (!payload.name) {
        return makeToastFaile('Vui lòng nhập tên cấu hình thuế!');
      }
      if (!payload.details || !payload.details.length) {
        return makeToastFaile('Vui lòng nhập chi tiết cấu hình thuế!');
      }
      if (!this.taxConfigModel.id) {
        return this.onCreate(payload);
      }
      this.onUpdate(payload);
    },
    onCreate(payload) {
      ApiService.post('tax-config', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.onCallApiSuccess();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    onUpdate(payload) {
      const data = {
        ...payload,
        id: this.taxConfigModel.id,
      };
      ApiService.put('tax-config', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.onCallApiSuccess();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    onClickToAdd() {
      const maxLevelItem = maxBy(this.taxConfigModel.details, 'level');
      const maxLevel = this.taxConfigModel.details.length
        ? maxLevelItem.level
        : 0;
      this.taxConfigModel.details.push({
        id: v4(),
        level: maxLevel + 1,
        incomeFrom: 0,
        incomeTo: 0,
        taxAmount: 0,
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.taxConfigModel.details.length; i++) {
        if (this.taxConfigModel.details[i].id === id) {
          this.taxConfigModel.details.splice(i, 1);
        }
      }
      this.taxConfigModel.details.forEach((element, index) => {
        element.level = index + 1;
      });
    },
    getInfoById(id) {
      ApiService.get(`tax-config/${id}`)
        .then(({ data }) => {
          this.taxConfigModel = data.data;
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    onCallApiSuccess() {
      this.hideModal();
      this.$emit('on-submit-success');
    },
  },
  components: {
    BInputNumber,
  },
};
</script>